<template>
  <div class="containerFormStoreLead">
    <p class="formTitle text-center">
      Preencha a senha do participante e preencha com o tempo.
    </p>
    <b-form @submit.prevent="saveLead">
      <b-form-group label="Buscar por" v-slot="{ ariaDescribedby }" class="radioOpts">
        <b-form-radio-group id="radio-group-2" v-model="searchBy" :aria-describedby="ariaDescribedby" name="radio-sub-component">
          <b-form-radio value="password">Senha</b-form-radio>        
          <b-form-radio value="cpf">CPF</b-form-radio>
        </b-form-radio-group>
      </b-form-group>
      <!-- <b-input-group class="cpfWithLoader" :class="{'invalidCPF':cpfError}">
        <b-form-input :state="!cpfError ? null : false" :disabled="gettingData" id="input-cpf" v-model="form.cpf" @change="loadLead" v-mask="'###.###.###-##'" type="text" placeholder="CPF" required></b-form-input>        
        <template #append v-if="gettingData">
          <div class="d-flex align-items-center aside">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
          </div>
        </template>
      </b-input-group>
      <p class="invalidCpf" v-if="cpfError">Digite um CPF válido.</p> -->
      <b-alert show :variant="alertMsg.variant" v-if="alertMsg.status">{{alertMsg.text}}</b-alert>
      <b-input-group class="passwordWithLoader">
        <b-form-input :disabled="gettingData || searchBy=='cpf'" id="input-password" v-model="form.password" @change="loadLead" type="text" placeholder="Senha" required></b-form-input>        
        <template #append v-if="gettingData && searchBy=='password'">
          <div class="d-flex align-items-center aside">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
          </div>
        </template>
      </b-input-group>
      <div class="mb-1" v-if="searchBy=='password'">
        <b-button class="mb-3 containerbSave d-flex align-items-center" style="font-size: 18px; padding: 5px 10px;" @click.prevent="loadLead">
          Fazer busca
        </b-button>
      </div>
      

      <b-input-group class="cpfWithLoader" :class="{'invalidCPF':cpfError}">
        <b-form-input :state="!cpfError ? null : false" @keyup="loadLead" :disabled="gettingData || searchBy=='password'" id="input-cpf" v-model="form.cpf" type="text" placeholder="CPF" v-mask="'###.###.###-##'" required></b-form-input>
        <template #append v-if="gettingData && searchBy=='cpf'">
          <div class="d-flex align-items-center aside">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
          </div>
        </template>
      </b-input-group>
      <p class="invalidCpfText" v-if="cpfError">Digite um CPF válido.</p>

      <b-form-group label-for="input-name">
        <b-form-input id="input-name" v-model="form.name" type="text" placeholder="Nome/Veículo" required readonly></b-form-input>
      </b-form-group>
      

      <b-form-group label-for="input-cpf">
        
      </b-form-group>

      <b-form-group label-for="input-telefone">
        <b-form-input readonly id="input-telefone" v-model="form.phone" v-mask="'(##) # ####-####'" type="text" placeholder="Telefone" required></b-form-input>
      </b-form-group>

      <b-form-group label-for="input-email">
        <b-form-input id="input-email" v-model="form.email" type="email" placeholder="Email" required readonly></b-form-input>
      </b-form-group>

      <!-- <div class="row">
        <div class="col">
          <label for="" class="timeLabel" :class="{'labelTimeDis':!form.name}">Minutos</label>
          <b-form-select v-model="form.time.minutes" required :disabled="!form.name">
            <b-form-select-option :value="index" v-for="(minute,index) in 60" :key="index">{{index}}</b-form-select-option>
          </b-form-select>
        </div>
        <div class="col">
          <label for="" class="timeLabel" :class="{'labelTimeDis':!form.name}">Segundos</label>
          <b-form-select v-model="form.time.seconds" required :disabled="!form.name">
            <b-form-select-option :value="index" v-for="(second,index) in 60" :key="index">{{index}}</b-form-select-option>
          </b-form-select>
        </div>
        <div class="col">
          <label for="" class="timeLabel" :class="{'labelTimeDis':!form.name}">Milisegundos</label>
          <b-form-select v-model="form.time.miliseconds" required :disabled="!form.name">
            <b-form-select-option :value="index" v-for="(milisecond,index) in 100" :key="index">{{index}}</b-form-select-option>
          </b-form-select>
        </div>
      </div> -->

      <div class="row">
        <div class="col">
          <b-form-group label-for="input-time">
            <b-form-input :disabled="!form.name" id="input-time" v-model="form.time" type="text" v-mask="'##:##:###'" placeholder="00:00:000 (tempo)" required></b-form-input>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <b-form-group label-for="input-time">
            <b-form-input :disabled="!form.name" id="input-time" v-model="form.timeCheck" type="text" v-mask="'##:##:###'" placeholder="00:00:000 (confirme o tempo)" required></b-form-input>
          </b-form-group>
        </div>
      </div>

      <div class="d-flex flex-column align-items-center justify-content-center containerInfo">
        <p class="text-center infoEnable" v-if="!form.name">Preencha com a senha ou CPF para liberar o tempo.</p>  
        <button type="button" v-else @click="cleanForm" class="text-center binfoEnable">Limpar</button>  
      </div>      

      <div class="d-flex justify-content-center">
        <span class="d-inline-block" tabindex="0" v-b-tooltip.top :title="!this.form.time || !this.form.timeCheck ? 'Preencha todos os campos.' : ''">
          <button :disabled="bSave.disabled || !this.form.time || !this.form.timeCheck" type="submit" class="containerbSave d-flex align-items-center">
            {{bSave.text}}
          </button>
        </span>   
      </div>


    </b-form>
  </div>
</template>

<script>
import { isCPF } from 'validation-br'
import {api} from '@/services.js'
export default {
name: 'FormStoreLead',
data() {
  return {
    cpfError: false,
    searchBy: 'password',
    lead: null,
    form: {
      cpf: null,
      name: null,
      time: null,
      email: null,
      phone: null,
      password: null,
      timeCheck: null
    },
    show: true,
    gettingData : false,
    alertMsg: {
      text: '',
      variant: '',
      status: ''
    },
    bSave: {
      disabled: false,
      text: 'Salvar'
    }
  }
},
methods: {
  cleanForm() {
    this.form = {
      cpf: null,
      name: null,
      time: null,
      email: null,
      timeCheck: null
    }
  },
  async saveLead() {
    this.alertMsg.status = false
    this.bSave.disabled = true
    this.bSave.disabled = 'Salvando'
    // if((!this.form.time.minutes || !this.form.time.seconds || !this.form.time.miliseconds)) {
    if((!this.form.time || this.form.time.length<9 || this.timeWrong())) {
      this.alertMsg.text =  'Preencha o tempo corretamente!'
      this.alertMsg.variant = 'warning'
      this.alertMsg.status = true
      this.bSave.disabled = 'Salvar'
      this.bSave.disabled = false
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
      return
    }else if(this.form.timeCheck != this.form.time) {
      this.alertMsg.text =  'Os campos de tempo devem ser iguais!'
      this.alertMsg.variant = 'warning'
      this.alertMsg.status = true
      this.bSave.disabled = 'Salvar'
      this.bSave.disabled = false
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
      return
    }

    await api.storeParticipation(this.lead.id,this.form).then(res=> {
      if(res.data.msg=='success') {
        this.alertMsg.text = 'Participação salva com sucesso, boa sorte!'
        this.alertMsg.variant = 'success'
        this.form = {
          cpf: null,
          name: null,
          time: null,
          email: null,
          phone: null,
          password: null,
          timeCheck: null
        }
      }else if(res.data.code == 1) {
        this.alertMsg.text = 'Usuário não encontrado!'
        this.alertMsg.variant = 'danger'
      }else if(res.data.code == 2) {
        this.alertMsg.text = 'Erro ao cadastrar participação, tente novamente!'
        this.alertMsg.variant = 'danger'
      } else {
        this.alertMsg.text = 'Erro ao cadastrar participação, tente novamente!'
        this.alertMsg.variant = 'danger'
      }
      this.alertMsg.status = true
    }).catch(err=> {
        console.log(err)
        this.alertMsg.text = 'Erro ao cadastrar participação, tente novamente!'
        this.alertMsg.variant = 'danger'
        this.alertMsg.status = true
    })
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    this.bSave.disabled = 'Salvar'
    this.bSave.disabled = false
  },
  async loadLead() {
    this.gettingData = true
    this.cpfError = false

    if(this.searchBy=='cpf' && !this.verifyIsCPF(this.form.cpf)) {
      this.cpfError = true
      this.gettingData = false
      return
    }
    
    this.alertMsg.status = false

    await api.getLeads(this.searchBy, this.searchBy=='cpf' ? this.form.cpf : this.form.password).then(res=> {
      if(res.data.msg=="success") {
        if(!res.data.data) {
          this.alertMsg.text="Usuário não encontrado. Tente por outro meio ou confirme os dados!"
          this.alertMsg.variant="danger"
          this.alertMsg.status = true
        }else {
          this.lead = res.data.data
          this.form.cpf = res.data.data &&   res.data.data.cpf ? res.data.data.cpf : ''
          this.form.name = res.data.data &&  res.data.data.name ? res.data.data.name : ''
          this.form.email = res.data.data && res.data.data.email ? res.data.data.email : ''
          this.form.phone = res.data.data && res.data.data.phone ? res.data.data.phone : ''
        }
      }else {
        
        this.alertMsg.text="Usuário não encontrado. Tente por outro meio ou confirme os dados!"
        this.alertMsg.variant="danger"
        this.alertMsg.status = true
        
        this.form = {
          cpf: null,
          name: null,
          time: null,
          email: null,
          phone: null,
          password: null,
          timeCheck: null
        }
        
      }
    }).catch(err=> {
      console.log(err)
      this.alertMsg.text="Senha não encontrada"
        this.alertMsg.variant="danger"
        this.alertMsg.status = true
    })

    this.gettingData = false
   
  },
  verifyIsCPF(cpf){
    if(!isCPF(cpf)){
      return false
    }

    return true
  },
  timeWrong() {
    var min = this.form.time.split(":")[0]
    var seg = this.form.time.split(":")[1]
    // var ms = this.form.time.split(":")[2]

    if(min>59) {
      return true
    }

    if(seg>59) {
      return true
    }

    return false
  }
},
watch: {
  searchBy() {
    this.form = {
      cpf: null,
      name: null,
      time: null,
      email: null,
      phone: null,
      password: null,
      timeCheck: null
    }
    this.cpfError = false
    this.alertMsg.status = false
  }
}
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/responsive.scss';
@import '@/assets/scss/fonts.scss';
.containerFormStoreLead {
  // background: rgba(134, 136, 139, 0.555);
  // backdrop-filter: blur(20px);  
  border-radius: 20px;
  padding: 40px 46px;
  margin-top: 207px;
  // border: 1px solid rgba(255,255,255,.5);

  @include d(md) {
    margin-top: 30px;
  }

  // @include d(sm) {
  //   margin-top: 390px;
  // }

  // @include d(xs) {
  //   margin-top: 380px;
  //   padding: 30px 15px;
  // }

  // @media(max-width:520px) {
  //   margin-top: 350px;
  // }

  // @media(max-width:451px) {
  //   margin-top: 330px;
  // }

  // @media(max-width:400px) {
  //   margin-top: 300px;
  // }

  // @media(max-width:350px) {
  //   margin-top: 290px;
  // }

  

  .formTitle {
    font-family: 'museo500';
    font-style: normal;    
    font-size: 40px;
    line-height: 49px;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    margin-bottom: 50px;

    @include d(lg) {
      font-size: 30px;
      line-height: 39px;
    }

    @include d(md) {
      font-size: 24px;
      line-height: 30px;
    }

    @include d(xs) {
      margin-bottom: 30px;      
    }
  }

  input, select {
    // background: #444849;
    border-radius: 10px;
    font-family: 'museo500';
    font-style: normal;
    // font-size: 25px;
    line-height: 30px;
    color: #FFFDEB;
    border: none;

    &::placeholder {
      color: #FFFDEB;
    }

    &:focus {
      // background: #444849;
      color: #FFFDEB;
      box-shadow: none;      
    }

    &:read-only {
      // background: rgba(0, 0, 0, 0.4);
      background: #444849;
      opacity: .4;
    }

    &:disabled {
      opacity: .4;
    }

    & + input {
      margin-top: 20px;
    }

    @include d(lg) {
      font-size: 22px;
      line-height: 25px;
    }

    @include d(xs) {
      font-size: 18px;
      line-height: 21px;
    }
  }

  select {
    &:disabled {
      opacity: .6;
    }
  }

  .ctaButton {
    max-width: 264px;
    cursor: pointer;
    transition: .2s;

    &.ctaDis {
      opacity: .8;
      filter: brightness(.8);
    }

    &:not(.ctaDis):hover {
      filter: brightness(.95);
    }

    @include d(lg) {
      max-width: 250px;
    }

    @include d(md) {
      max-width: 230px;
    }

    @include d(xs) {
      max-width: 200px;
    }
  }

  .containerCheckbox {
    color: #fff;
  }
  
  .cpfWithLoader, .passwordWithLoader {

    &:not(.invalidCPF) {
      margin-bottom: 20px;
    }

    input {
      border-right: none;
    }

    .aside {
      background: #444849;
      backdrop-filter: blur(20px);
      border-radius: 0 10px 10px 0;
      padding: 0 10px;
    }

  }

  .timeLabel {
    font-size: .9rem;
    color: #fff;
    margin-bottom: 3px;

    &.labelTimeDis {
      opacity: .7;
    }
  }

  .infoEnable {
    color: #fff;
    font-size: .9rem;
    
  }

  .binfoEnable {
    background: #6f7070;
    color: #fff;
    border-radius: 8px;
    padding: 5px 25px;
    width: fit-content;
    border: none;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.6);
    transition: .2s;
    &:hover {
      background: #009D93;
    }
  }

  .containerInfo {
    margin-top: 20px;
  }
  .invalidCpfText { 
    color: #ffffff;
    font-size: .9rem;
    margin: 10px 0 20px 0;
  }

  .radioOpts {
    color: #fff;
  }
}
</style>
