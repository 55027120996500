<template>
  <b-modal :id="`modalLeadPlays${type}`" title="Participações de jogador">
    <p class="leadName">
        Participações de <b>{{lead && lead.name ? lead.name: ''}}</b>
    </p>
    <b-table striped hover responsive :fields="fieldsTable" :items="lead.participation" thead-tr-class="text-center" tbody-tr-class="text-center">
        <template #cell(name)>
            <div class="text-left">
                {{lead && lead.name ? lead.name: ''}}
            </div>
        </template>
    </b-table>
  </b-modal>
</template>

<script>
export default {
name: 'ModalLeadPlays',
props:['lead','type'],
data() {
    return {
        fieldsTable : [
            {
                key: 'id',
                label: 'ID'
            },
            {
                key: 'name',
                label: 'Nome'
            },
            {
                key: 'duration',
                label: 'Tempo'
            },            
        ],
    }
}
}
</script>

<style lang="scss" scoped>
.leadName {
    padding: 15px 0;
}
</style>