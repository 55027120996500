<template>
  <div class="containerDashboard">
    <DashboardHeader/>
    
    <section id="containerDash">
      <div class="container-fluid">
        <div class="row">
          <div class="col">
            <div class="card">
              <div class="card-header">
                <i class="fas fa-table mr-2"></i> Dashboard
              </div>
              <div class="card-body">
                <b-tabs content-class="mt-3">
                  <b-tab title="Simulador" active>
                    <div class="card">
                      <div class="card-header">
                        <i class="fas fa-table mr-2"></i> Dahsboard Simulador
                      </div>
                      <div class="d-flex w-100 justify-content-center py-4" v-if="!loaded">
                        <b-spinner/>
                      </div>
                      <div class="card-body" v-else>
                        <b-tabs content-class="mt-3">
                          <b-tab title="Geral (Todos os dias)" active>
                            <BudgetsDashboard :loaded.sync="loaded"/>
                            <RankingDashboard :loadedRanking.sync="loadedRanking"/>
                            <DashLeadsTable :loaded.sync="loaded"/>
                          </b-tab>
                          <b-tab :title="makeTabTitle(index+1)" v-for="(tab,index) in ammountDays" :key="index">
                            <Budgets :day="21+index+1" :indexInList="index+1" :loaded.sync="loaded"/>
                            <RankingDay :day="21+index+1" :indexInList="index+1" :loadedRanking.sync="loadedRanking"/>
                            <LeadsDayTable :day="21+index+1" :indexInList="index+1" :loaded.sync="loadedRanking"/>
                          </b-tab>
                        </b-tabs>
                      </div>
                    </div>
                  </b-tab>
                  <!-- <b-tab title="Flappy Motors">
                    <TabsFlap :ammountDays="ammountDays"/>
                  </b-tab> -->
                </b-tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {api} from '@/services.js'
import DashboardHeader from '@/components/Dashboard/DashboardHeader.vue'
import BudgetsDashboard from '@/components/Dashboard/Tabs/Simulador/Geral/BudgetsDashboard.vue'
import DashLeadsTable from '@/components/Dashboard/Tabs/Simulador/Geral/DashLeadsTable.vue'
import RankingDashboard from '@/components/Dashboard/Tabs/Simulador/Geral/RankingDashboard.vue'

import Budgets from '@/components/Dashboard/Tabs/Simulador/Days/Budgets.vue'
import RankingDay from '@/components/Dashboard/Tabs/Simulador/Days/RankingDay.vue'
import LeadsDayTable from '@/components/Dashboard/Tabs/Simulador/Days/LeadsDayTable.vue'

// import TabsFlap from '@/components/Dashboard/TabsFlap.vue'

export default {
name: 'DashboardPage',
components: {
    DashboardHeader,
    BudgetsDashboard,
    DashLeadsTable,
    RankingDashboard,
    Budgets,
    RankingDay,
    LeadsDayTable,
    // TabsFlap
},
data() {
  return {
    loaded: false,
    loadedRanking: false,
    ammountDays: 0,
  }
},
methods: {
  makeTabTitle(tab) {
    if(tab<5) {
      return `${21 + tab}/06`
    }
  },
  async loadAnalystics() {
    await api.getAnalystics().then(res=> {
      if(res.data.msg=='success') {
        this.$store.commit('SET_ANALYSTICS',res.data.analystics)
        this.$store.commit('SET_TIMEINFO',res.data.dateTimeInfo)
        this.$store.commit('SET_LEADS',res.data.leads)
        this.$store.commit('SET_PARTICIPATIONS',res.data.participations)
        // this.analystics = res.data.analystics
        // this.leads = res.data.leads
        var allRank = res.data.ranking.all.sort((a,b)=>
          a.duration_time < b.duration_time ? -1 : 1
        )
        var userRank = res.data.ranking.general.sort((a,b)=>
          a.duration_time < b.duration_time ? -1 : 1
        )
        var jornalistRank = res.data.ranking.jornalists.sort((a,b)=>
          a.duration_time < b.duration_time ? -1 : 1
        )

        this.$store.commit('SET_RANKING_ALL',allRank)
        this.$store.commit('SET_RANKING_JORNALIST',jornalistRank)
        this.$store.commit('SET_RANKING_USER',userRank)
        this.calcAmmountDays()
        
      }
    })

    setTimeout(() => {
      this.loaded = true
      this.loadedRanking = true
    }, 1000);
  },
  async loadRanking() {
    this.loadedRanking = false
    
    await api.loadRanking().then(res=> {
      
      // this.ranking = res.data.data
      var rank = res.data.data.sort((a,b)=>
        a.duration_time < b.duration_time ? -1 : 1
      )
      
      this.$store.commit('SET_RANKING',rank)
      
    })

    this.loadedRanking = true
  },
  calcAmmountDays() {
        var dateNow = new Date(1687402800 * 1000)
        // var dateLive = new Date(1668481200 * 1000)
        var dateLive = new Date(this.timeinfo.today * 1000)

        
        var absolute =  Math.abs(dateLive - dateNow)
        
        if(absolute>0) {
          this.ammountDays = Math.trunc(absolute / (1000 * 60 * 60 * 24))
        }

        this.$store.commit('SET_AMMOUNT_DAYS',this.ammountDays)
        this.$store.dispatch('createUsersWithPlaysArr')
  }
},
created() {
  this.loadAnalystics()
  // this.loadRanking()
},
computed: {
  ...mapState(['timeinfo'])
}
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/responsive.scss';
.containerDashboard {
    max-width: 1920px;
    width: 100%;
    background: #f6f6f6;
    min-height: 100vh;
    padding: 30px 50px;
    margin: 0 auto;

    @include d(xs) {
      padding: 20px 0;
    }
}
</style>