<template>
<div v-if="leadsList.length>0">
    <b-table striped hover responsive :fields="fieldsTable" :items="leadsList" thead-tr-class="text-center" tbody-tr-class="text-center">
        <template #head(name)>
            <div class="text-left">
                Nome
            </div>
        </template>
        <template #head(email)>
            <div class="text-left">
                E-mail
            </div>
        </template>
        <template #cell(name)="data">
            <div class="text-left">
                {{data.item.name}}
            </div>
        </template>
        <template #cell(email)="data">
            <div class="text-left">
                {{data.item.email}}
            </div>
        </template>
        <template #cell(type)="data" v-if="type=='all'">
            <div class="">
                {{
                    data.item.type == 'jornalist' ? 'Jornalista' : 'Usuário'
                }}
            </div>
        </template>
        <template #cell(seeParticipations)="data">
            <div class="d-flex pl-3">
                <button @click.prevent="openModalLead(data.item)" class="btn btn-primary">Ver participações</button>
            </div>
        </template>
    </b-table>
    <ModalLeadPlays :type="actualPlays.type" :lead.sync="actualPlays.lead"/>
</div>
<b-alert v-else show variant="warning"><i class="fas fa-info-circle mr-2"></i>Sem dados para mostrar.</b-alert>
</template>

<script>
import ModalLeadPlays from '@/components/Dashboard/ModalLeadPlays.vue'
export default {
name: 'TableLeads',
props:['leadsList','type'],
components: {
    ModalLeadPlays
},
data() {
    return {
        fieldsTable : [
             {
                key: 'id',
                label: 'ID'
            },
            {
                key: 'name',
                label: 'Nome'
            },
            {
                key: 'email',
                label: 'E-mail'
            },
            {
                key: 'cpf',
                label: 'CPF'
            },
            {
                key: 'phone',
                label: 'Telefone'
            },
            {
                key: 'seeParticipations',
                label: 'Participações'
            }
        ],
        actualPlays: {
            lead: {},
            type: ''
        }
    }
},
methods: {
    openModalLead(lead) {
        this.actualPlays.lead = lead
        this.actualPlays.type = this.type
        this.$bvModal.show(`modalLeadPlays${this.type}`)
    }
},
created() {
    this.actualPlays.type = this.type
    if(this.type=='all') {
        var searchItem = this.fieldsTable.filter(c=>c.key=='email')[0]
        var indexSearch = this.fieldsTable.indexOf(searchItem)

        this.fieldsTable.join()
        this.fieldsTable.splice(indexSearch,0, {
            key: 'type',
            label: 'Tipo'
        },)

        this.fieldsTable.join()
    }
}
}
</script>

<style>

</style>