<template>
  <div class="container-fluid containerHeader">
    <div class="row">
        <div class="col">
            <div class="d-flex justify-content-between align-items-center">
                <router-link :to="{name: 'Index'}">
                    <img src="@/assets/img/logoEvento2.png" alt="" class="logo img-fluid">
                </router-link>

                <router-link :to="{name: 'Index'}">
                    <img src="@/assets/img/LogoPetronas.png" alt="" class="logo2 img-fluid">
                </router-link>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
name: 'DashboardHeader'
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/responsive.scss';
.containerHeader {
    // padding: 30px 50px;
    margin-bottom: 50px;

    @include d(xs) {
        margin-bottom: 30px;
    }
}
.logo {
    max-height: 80px;
    @include d(xs) {
        max-height: 70px;
    }
}

.logo2 {
    max-height: 70px;
    @include d(xs) {
        max-height: 60px;
    }
}
</style>