<template>
  <div class="indexPage">
    <div class="container">
      <div class="indexPage__content">
        <div class="row">
          <div class="col">
            <router-link :to="{name: 'Index'}">
              <img src="@/assets/img/logoPetronasBranco.svg" alt="" class="logoPetronas">
            </router-link>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <img src="@/assets/img/logoEvento2.png" alt="" class="logoEvento" />
          </div>
        </div>
  
        <div class="indexPage__links">
          <router-link :to="{name: 'StoreLead'}" target="_blank" class="link">
            <b-icon icon="clipboard"></b-icon>

            Cadastro Simulador
          </router-link>

          <router-link :to="{name: 'LeadPlayView'}" target="_blank" class="link">
            <b-icon icon="stopwatch"></b-icon>
            Cadastro de tempos
          </router-link>

          <router-link :to="{name: 'Ranking'}" target="_blank" class="link">
            <b-icon icon="list" color="#ffff"></b-icon>
            Ranking simulador
          </router-link>

          <a href="https://br.pli-petronas.com/pt-br/distribuidores#/br" target="_blank" class="link">
            <!-- <b-icon icon="truck"></b-icon> -->
            <b-icon icon="wrench"></b-icon>
            ENCONTRE um distribuidor
          </a>
          
          <!-- <a href="https://smiles-mkt.s3.amazonaws.com/promo-va-de-milhas/regulamento.pdf" class="link">
            <img src="/motorcycle-svgrepo-com.svg" alt="" style="width: 30px;" class="mr-2">
            Onde comprar petronas sprinta
          </a> -->
          <router-link :to="{name: 'pdfondecomprar'}" target="_blank" class="link">
            <img src="/motorcycle-svgrepo-com.svg" alt="" style="width: 30px;" class="mr-2">
            Onde comprar petronas sprinta
          </router-link>

          <a href="https://form.jotform.com/231673859801060" target="_blank" class="link mb-0">
            <b-icon icon="cart"></b-icon>
            Cadastro Moto Clube
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from "axios"

export default {
  name: 'IndexPage',

  data(){
    return{

    }
  },

  methods: {
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/responsive.scss';

.indexPage{
  background: url("@/assets/img/bg3.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  box-sizing: border-box;
  padding: 60px 0;

  &__content{
    min-height: calc(100vh - 120px);
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    box-sizing: border-box;
  }

  &__links{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    padding-top: 50px;
    box-sizing: border-box;
  }
}

.logoPetronas {
  position: absolute;
  top: 0px;
  right: 0;
  max-width: 83px;
  z-index: 9;

  @include d(md) {
    max-width: 77px;
  }

  @include d(sm) {
    max-width: 65px;
  }

  @include d(xs) {
    max-width: 60px;
    right: 10px;
  }
}

.logoEvento {
  width: 100%;
  max-width: 488px;
  display: block;
  margin: 0 auto;
}

.link{
  width: 540px;
  display: block;
  text-align: center;
  background-color: #1ea69b;
  border-radius: 10px;
  color: #fff;
  font-family: 'museo900';
  font-size: 25px;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  border: 3px solid #126059;
  margin: 0 auto 20px auto;
  padding: 15px 15px;

  &:last-child{
    margin-bottom: 0;
  }

  svg{
    max-width: 25px;
    margin-right: 5px;
  }
}
</style>