<template>
<section id="containerBudgetsDash">
  <div class="container-fluid">
    <div class="row align-items-stretch">
      <div class="col-12 col-sm-6 col-md-4 col-lg-3">
        <div class="boxBudget">
          <div v-if="loaded">
            <p class="mb-0 boxTitle">
              <i class="fas fa-users mr-2"></i>Total de cadastros 
            </p>
            <p class="boxText mb-0">
              {{analystics.ammountLeads ? analystics.ammountLeads : '---'}}
            </p>
          </div>
          <div class="d-flex w-100 justify-content-center" v-else>
            <b-spinner/>
          </div>
        </div>
      </div>
      <div class="w-100 d-sm-none my-2"></div>
      <div class="col-12 col-sm-6 col-md-4 col-lg-3">
        <div class="boxBudget">
          <div v-if="loaded">
            <p class="mb-0 boxTitle">
              <i class="fas fa-gamepad mr-2"></i>Total de jogadas
            </p>
            <p class="boxText mb-0">
              {{analystics.ammountPlays ? analystics.ammountPlays : '---'}}
            </p>
          </div>
          <div class="d-flex w-100 justify-content-center" v-else>
            <b-spinner/>
          </div>
        </div>
      </div>
      <div class="w-100 d-md-none my-2"></div>
      <div class="col-12 col-sm-6 col-md-4 col-lg-3">
        <div class="boxBudget">
          <div v-if="loaded">
            <p class="mb-0 boxTitle">
              <i class="fas fa-dice mr-2"></i>Média de jogos por lead
            </p>
            <p class="boxText mb-0">
              {{analystics.avgPlayPerPlayer ? analystics.avgPlayPerPlayer : '---'}}
            </p>
          </div>
          <div class="d-flex w-100 justify-content-center" v-else>
            <b-spinner/>
          </div>
        </div>
      </div>
      <div class="w-100 d-sm-none d-md-block d-lg-none my-2"></div>
      <div class="col-12 col-sm-6 col-md-4 col-lg-3">
        <div class="boxBudget">
          <div v-if="loaded">
            <p class="mb-0 boxTitle">
              <i class="fas fa-stopwatch mr-2"></i>Menor tempo
            </p>
            <p class="boxText mb-0">
              {{analystics.lowerTime ? analystics.lowerTime.duration : '-- : -- : ---'}}
            </p>
          </div>
          <div class="d-flex w-100 justify-content-center" v-else>
            <b-spinner/>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
</template>

<script>

import {mapState} from 'vuex'
export default {
name: 'BudgetsDashboard',
props: ['loaded'],
computed: {
  ...mapState(['analystics'])
}

}
</script>

<style lang="scss" scoped>
#containerBudgetsDash {
    .boxBudget {
      border-radius: 8px;
      box-shadow: 0 0 3px rgba(0,0,0,.3);
      background: #fff;
      padding: 16px 22px;
      height: 100%;

      .boxTitle {
        font-size: 1.15rem;
        font-family: 'museo700';
      }

      .boxText {
        margin-top: 10px;
        font-size: 1.5rem;
        font-family: 'museo900';
      }
    }
}
</style>