<template>
<section id="containerDashLeadsTable">
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <div class="card">
                    <div class="card-header">
                        <i class="fas fa-users mr-2"></i>Leads e suas jogadas (Todos os dias)
                    </div>
                    <div class="card-body" v-if="loaded">
                        <b-tabs content-class="mt-3" >
                            <b-tab title="Todos" active>
                                <TableLeads :type="'all'" :leadsList="leads.all"/>
                            </b-tab>
                            <b-tab title="Geral">
                                <TableLeads :type="'user'" :leadsList="leads.general"/>
                            </b-tab>
                            <b-tab title="Jornalistas">
                                <TableLeads :type="'jornalist'" :leadsList="leads.jornalists"/>
                            </b-tab>
                        </b-tabs>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import {mapState} from 'vuex'
import TableLeads from '@/components/Dashboard/TableLeads.vue'
export default {
name: 'DashLeadsTable',
props:['loaded'],
components: {
    TableLeads
},
computed: {
  ...mapState(['leads'])
}
}
</script>

<style lang="scss" scoped>
#containerDashLeadsTable {
    margin-top: 30px;
}
</style>