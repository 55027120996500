<template>
  <div style="background-color: #000; height: 100vh; display: flex; align-items: center; box-sizing: border-box;"> 
    <img src="/Onde Comprar PETRONAS Sprinta1.jpg" alt="PDF ONDE COMPRAR PETRONAS SPRINTA" style="display: block; object-fit: contain; max-height: 90vh; box-sizing: border-box; margin: 0 auto;">
    <!-- <iframe
        src="https://motorace.petronassprinta.com.br/onde-comprar-PETRONAS-Sprinta.pdf"
        width="100%"
        style="height: 99vh; box-sizing: border-box; margin: 0;"
    ></iframe> -->
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>