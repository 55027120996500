import axios from 'axios'

// const url = 'http://localhost:8000'
// const url = 'https://mre.petronas.com.br/api/public'
const url = 'https://motorace.petronassprinta.com.br/api/public'

const axiosInstance = axios.create({
  baseURL : url
})

export const api = {
  get(endpoint) {
    return axiosInstance.get(endpoint);
  },
  post(endpoint, body) {
    return axiosInstance.post(endpoint, body);
  },
  put(endpoint, body) {
    return axiosInstance.put(endpoint, body);
  },
  delete(endpoint) {
    return axiosInstance.delete(endpoint);
  },
  getLeads(by,searchData) {
    var sendData = by == 'cpf' ? searchData.replace(/[^a-zA-Z 0-9]+/g,'') : searchData
    return axiosInstance.get(`/leads/${by}/${sendData}`)
  },
  storeLeads(body) {
    return axiosInstance.post('/leads/store',body)
  },
  storeParticipation(leadId,body) {
    return axiosInstance.post(`/participation/${leadId}/store`,body)
  },
  loadRanking() {
    return axiosInstance.get('/stgranking')
    // return axiosInstance.get('/ranking')
  },
  getAnalystics() {
    return axiosInstance.get('/analystics')
  },
  getAnalysticsFlap() {
    return axiosInstance.get('/analystics-flap')
  },
};