<template>
  <section id="containerRanking2">
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <div class="card">
                    <div class="card-header">
                        <i class="fas fa-trophy mr-2"></i>Ranking ({{`${day}/06`}})
                    </div>
                    <div class="card-body" v-if="loadedRanking">
                        
                        <b-table striped hover responsive :fields="fieldsTable" :items="rankingDay" thead-tr-class="text-center" tbody-tr-class="text-center">
                            <template #cell(position)="data">
                                <div class="text-center">
                                    {{data.index + 1}}º
                                </div>
                            </template>
                            <template #cell(name)="data">
                                <div class="text-center">
                                    {{data.item.lead.name}}
                                </div>
                            </template>
                            <template #cell(phone)="data">
                                <div class="text-center">
                                    {{data.item.lead.phone}}
                                </div>
                            </template>
                            <template #cell(type)="data">
                                <div class="text-center">
                                    {{data.item.lead.type}}
                                </div>
                            </template>
                            <template #cell(duration_time)="data">
                                <div class="text-center">
                                    {{data.item.duration}}
                                </div>
                            </template>
                            
                        </b-table>                              
                        
                    </div>
                    <div class="d-flex w-100 justify-content-center py-4" v-else>
                        <b-spinner/>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
import {mapState} from 'vuex'
export default {
name: 'RankingDay',
props:['loadedRanking','day','indexInList'],
data() {
    return {
        fieldsTable : [
            {
                key: 'position',
                label: 'Posição'
            },
            {
                key: 'lead_id',
                label: 'ID'
            },
            {
                key: 'name',
                label: 'Nome'
            },
            {
                key: 'phone',
                label: 'Telefone'
            },
            {
                key: 'type',
                label: 'Tipo'
            },
            {
                key: 'duration_time',
                label: 'Tempo'
            },            
        ],
        rankingDay: [],
        usersDay: []
    }
},
methods: {
    loadDay() {
        this.usersDay = this.$store.state[`day${this.indexInList}`]

        for(var i=0;i<this.ammountDays;i++) {
            for(var j=0;j<this.$store.state[`day${i+1}`].length;j++) {
                this.ammountPlays+= this.$store.state[`day${i+1}`][j][`playsDay${this.indexInList}`].length
                if(this.$store.state[`day${i+1}`][j][`playsDay${this.indexInList}`][0]) {
                    var playDay = this.$store.state[`day${i+1}`][j][`playsDay${this.indexInList}`][0]
                    this.rankingDay = [...this.rankingDay,playDay]
                }
            }
        }

        this.rankingDay.sort((a,b)=>
          a.duration < b.duration ? -1 : 1
        )
    }
},
created() {
    this.loadDay()
},
computed: {
  ...mapState(['ammountDays'])
}
}
</script>

<style lang="scss" scoped>
#containerRanking2 {
    margin-top: 30px;
   

    .card-body {
        max-height: 400px;
        overflow-y: scroll;
    }
}
</style>