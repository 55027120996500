<template>
  <section id="containerRanking">
    <div class="container">
      <div class="row">
        <div class="col px-0">
          <div class="d-flex justify-content-center">
            <img src="@/assets/img/logo.png" alt="" class="logoEvent img-fluid">
          </div>
          <div class="d-flex flex-column align-items-center jutify-content-center w-100" v-if="canShowRanking">
            <p class="prizeText">
              Todos os dias,<br/>
              <span>os 3 melhores colocados</span> no simulador ganham <br class="d-block d-sm-none"/>
              <span>prêmios exclusivos!</span>
            </p>
            <img v-if="participationType && participationType=='user'" src="@/assets/img/prizes/premios.png" class="img-fluid imgPrize" alt="">
            <img v-if="participationType && participationType=='jornalist'" src="@/assets/img/prizes23/23_premios.png" class="img-fluid imgPrize" alt="">
            <div class="containerRankingBox d-flex flex-column align-items-center">
              <div class="boxHeader">
                <span>Ranking</span>
              </div>
              <div class="containerPlayers">
                <div class="boxPlayer d-flex align-items-center justify-content-between" v-for="(player,index) in ranking.slice(0,limitSlice)" :key="index">
                  <p class="playerName mb-0">{{player.name}}</p>
                  <p class="playerScore mb-0">{{player.duration_time}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="containerVideo" v-if="!canShowRanking">
      <!-- <div class="cover" @click="playVideo"></div> -->
      <!-- <youtube :video-id="videoId" ref="youtube" :player-vars="playerVars" v-if="!canShowRanking"  @ended="allowRanking"></youtube> -->
      <vimeo-player  ref="player"  :options="playerVars" :video-id="videoId"  @ended="allowRanking"></vimeo-player>
    </div>
  </section>
</template>

<script>
import {api} from '@/services.js'
export default {
  name: 'RankingView',
  data() {
    return {
      participationType: null,
      canShowRanking: false,
      videoId: '835931122',
      limitSlice: 10,
      playerVars: {
        muted: true,
        autoplay: true,
        controls: false,
        allowfullscreen: true,
      },
      ranking: [
        {id:1,name:'Nome',score:999999},
        {id:2,name:'Nome',score:999999},
        {id:3,name:'Nome',score:999999},
        {id:4,name:'Nome',score:999999},
        {id:5,name:'Nome',score:999999},
      ],
      videoStatus: true
    }
  },
  methods: {
    allowRanking() {
      this.canShowRanking = true
    },
    playVideo() {
      this.videoStatus = !this.videoStatus
      if(this.videoStatus) {
        this.$refs['player'].pause()
      }else {
        this.$refs['player'].play()
      }
    },
    async loadRanking() {
      await api.loadRanking().then(res=> {
        var resRank = res.data.data

        // for(var i=0;i<resRank.length;i++) {
        //     resRank[i].duration = resRank[i].participation[0].duration
        // }

        this.ranking = resRank.sort((a,b)=>
            a.duration_time < b.duration_time ? -1 : 1
        )
        this.participationType = res.data.participationType
      })
    },
    onReady() {
      // this.playerReady = true
      // this.playVideo()

    },
  },
  created() {
    this.loadRanking()
  }
}

</script>

<style lang="scss">
@import '@/assets/scss/responsive.scss';
#containerRanking {
  max-width: 991px;
  margin: 0 auto;
  min-height: 100vh;
  background: url('@/assets/img/LogoPetronas.png'), url('@/assets/img/bgRanking.png');
  background-repeat: no-repeat;
  background-position: calc(100% - 50px) 50px , center bottom;
  background-size: auto, cover;
  padding: 0 50px 80px 50px;
  // background-position: calc(100% - 50px) 50px , center 70px, center bottom;
  // background-size: auto,auto, cover;
  // background: url('@/assets/img/LogoPetronas.png'), url('@/assets/img/logo.png'), url('@/assets/img/bgRanking.png');

  @include d(sm) {
    background-size: 70px auto, cover;
    background-position: calc(100% - 30px) 30px, center bottom;
    padding: 0;
    padding-bottom: 50px;
  }

  @include d(xs) {
    background-size: 50px auto, cover;
    background-position: calc(100% - 15px) 15px, center bottom;
  }

  .logoEvent {
    margin-top: 70px;
  }

  .containerRankingBox {
    margin-top: 97px;
    background: linear-gradient(0deg, #17333450, #dae6e250,#dae6e250, #dae6e250);
    // min-height: 500px;
    width: 100%;
    position: relative;
    border-radius: 5px;
    padding: 0 30px;
    padding-bottom: 30px;

    @include d(xs) {
      padding: 0 15px;
      margin-top: 68px;
      padding-bottom: 15px;
    }

    .boxHeader {
      background: linear-gradient(90deg, #41c47e, #41c47e,  #d9f9f2);
      max-width: 400px;
      font-size: 63px;
      // font-size: 48px;
      color: #fff;
      border-radius: 3px;
      text-transform: uppercase;
      font-family: 'museo900';
      text-align: center;
      position: relative;
      padding: 0 15px;
      margin-top: -47px;

      @include d(sm) {
        font-size: 48px;
        margin-top: -36px;
      }

      @include d(xs) {
        font-size: 38px;
        margin-top: -28.5px;
        background: linear-gradient(90deg, #41c47e, #41c47e, #41c47e, #41c47e, #41c47e, #d9f9f2);
      }

      &::before {
        content: '';
        position: absolute;
        width: 25px;
        height: 45px;
        top: calc((100% - 45px)/2);
        left: -25px;
        background: #323332;
        border-radius: 3px 0 0 3px;

        @include d(xs) {
          width: 15px;
          left: -15px;
          height: 30px;
          top: calc((100% - 30px)/2);
        }
      }

      &::after {
        content: '';
        position: absolute;
        width: 25px;
        border-radius: 0 3px 3px 0;
        height: 45px;
        top: calc((100% - 45px)/2);
        right: -25px;
        background: #323332;

        @include d(xs) {
          width: 15px;
          right: -15px;
          height: 30px;
          top: calc((100% - 30px)/2);
        }
      }
    }

    .containerPlayers {
      margin-top: 40px;
      width: 100%;
    }

    .boxPlayer {
      background: #fff;
      box-shadow: 0 0 3px rgba(0,0,0,.3);
      padding: 5px 20px;
      width: 100%;
      border-radius: 25px;

      & + .boxPlayer {
        margin-top: 16px;
      }

      .playerName {
        // font-size: 38px;
        font-size: 26px;
        font-family: 'museo300';

        @include d(sm) {
          font-size: 26px;
        }

        @include d(xs) {
          font-size: 20px;
        }
      }

      .playerScore {
        width: 150px;
        // font-size: 38px;
        font-size: 26px;
        font-family: 'museo300';
        text-align: right;

        @include d(sm) {
          font-size: 26px;
        }

        @include d(xs) {
          font-size: 18px;
        }
      }
    }
  }





  .imgPrize {
    max-width: 80%;
    margin-top: 50px;
  }

  .prizeText {
    font-size: 1.5rem;
    font-family: 'museo300';
    color: #323332;
    text-transform: uppercase;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 0;

    @include d(sm) {
      font-size: 1.25rem;
    }

    @include d(xs) {
      margin-top: 20px;
      font-size: 1.15rem;
    }
    span {
      font-family: 'museo900';
      background: #009E94;
      color: #fff;
      padding: 0 10px;
    }
  }
}

.containerVideo {
  // margin-top: 70px;
  // position: relative;

  // .cover {
  //     width: 100%;
  //     height: 100%;
  //     background: transparent;
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  // }
  // position: absolute;
  // top: 0;
  // left: 0;
  // width: 100%;
  // height: 100;
  // z-index: 20;
  background: #000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  // > div {
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  //     height: 100%;
  //     width: 100%;
  // }

  background: #000;
  iframe {
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    height: 100% !important;
    border: 0;

  }
}

.iframeVimeo {

  iframe {

  }

}

</style>
