<template>
<section id="containerDashLeadsTable">
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <div class="card">
                    <div class="card-header">
                        <i class="fas fa-users mr-2"></i>Leads e suas jogadas ({{`${day}/06`}})
                    </div>
                    <div class="card-body" v-if="loaded">
                        
                        <div v-if="usersDay.length>0">
                            <b-table striped hover responsive :fields="fieldsTable" :items="usersDay" thead-tr-class="text-center" tbody-tr-class="text-center">
                                <template #head(name)>
                                    <div class="text-left">
                                        Nome
                                    </div>
                                </template>
                                <template #head(email)>
                                    <div class="text-left">
                                        E-mail
                                    </div>
                                </template>
                                <template #cell(name)="data">
                                    <div class="text-left">
                                        {{data.item.name}}
                                    </div>
                                </template>
                                <template #cell(email)="data">
                                    <div class="text-left">
                                        {{data.item.email}}
                                    </div>
                                </template>
                                <template #cell(type)="data">
                                    <div class="">
                                        {{
                                            data.item.type == 'jornalist' ? 'Jornalista' : 'Usuário'
                                        }}
                                    </div>
                                </template>
                                <template #cell(seeParticipations)="data">
                                    <div class="d-flex pl-3">
                                        <button @click.prevent="openModalLead(data.item)" class="btn btn-primary">Ver participações</button>
                                    </div>
                                </template>
                            </b-table>
                            <ModalLeadPlaysDay :indexInList="indexInList" :lead.sync="actualPlays.lead"/>
                        </div>
                        <b-alert v-else show variant="warning"><i class="fas fa-info-circle mr-2"></i>Sem dados para mostrar.</b-alert>
                           
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import {mapState} from 'vuex'
import ModalLeadPlaysDay from '@/components/Dashboard/ModalLeadPlaysDay.vue'
export default {
name: 'DashLeadsTable',
props:['loaded','day','indexInList'],
components: {
    ModalLeadPlaysDay
},
data() {
    return {
        fieldsTable : [
             {
                key: 'id',
                label: 'ID'
            },
            {
                key: 'name',
                label: 'Nome'
            },
            {
                key: 'email',
                label: 'E-mail'
            },
            {
                key: 'cpf',
                label: 'CPF'
            },
            {
                key: 'phone',
                label: 'Telefone'
            },
            {
                key: 'seeParticipations',
                label: 'Participações'
            }
        ],
        actualPlays: {
            lead: {},
            type: ''
        },
        rankingDay: [],
        usersDay: []
    }
},
methods: {
    loadDay() {
        this.usersDay = this.$store.state[`day${this.indexInList}`]

        for(var i=0;i<this.ammountDays;i++) {
            for(var j=0;j<this.$store.state[`day${i+1}`].length;j++) {
                this.ammountPlays+= this.$store.state[`day${i+1}`][j][`playsDay${this.indexInList}`].length
                if(this.$store.state[`day${i+1}`][j][`playsDay${this.indexInList}`][0]) {
                    var playDay = this.$store.state[`day${i+1}`][j][`playsDay${this.indexInList}`][0]
                    this.rankingDay = [...this.rankingDay,playDay]
                }
            }
        }

        this.rankingDay.sort((a,b)=>
          a.duration < b.duration ? -1 : 1
        )
    },
    openModalLead(lead) {
        this.actualPlays.lead = lead[`playsDay${this.indexInList}`]
        this.actualPlays.type = "user"
        this.$bvModal.show(`modalLeadPlaysDayUser${this.indexInList}`)
    }
},
created() {
    this.loadDay()
},
computed: {
  ...mapState(['ammountDays'])
}
}
</script>

<style lang="scss" scoped>
#containerDashLeadsTable {
    margin-top: 30px;
}
</style>