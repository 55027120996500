import { render, staticRenderFns } from "./LeadsDayTable.vue?vue&type=template&id=6d2b435a&scoped=true&"
import script from "./LeadsDayTable.vue?vue&type=script&lang=js&"
export * from "./LeadsDayTable.vue?vue&type=script&lang=js&"
import style0 from "./LeadsDayTable.vue?vue&type=style&index=0&id=6d2b435a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d2b435a",
  null
  
)

export default component.exports