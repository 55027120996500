<template>
<section id="containerStoreLead">
  <div class="container">
    <div class="row">
      <div class="col">
        <router-link :to="{name: 'Index'}">
          <img src="@/assets/img/logoPetronasBranco.svg" alt="" class="logoPetronas">
        </router-link>
        <img src="@/assets/img/logoEvento2.png" alt="" class="logoEvento d-none d-lg-block">
        <div class="row justify-content-end">
          <div class="col-12 col-lg-6">
              <div class="d-flex align-items-center justify-content-end flex-column">
                <img src="@/assets/img/logoEvento2.png" alt="" class="logoEvento2 d-lg-none img-fluid">
                <FormLeadPlay/>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
</template>

<script>
import FormLeadPlay from '@/components/LeadPlay/FormLeadPlay.vue'
export default {
  name: 'StoreLead',
  components: {
    FormLeadPlay
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/responsive.scss';
#containerStoreLead {  
  // background: url('@/assets/img/moto.png'), url('@/assets/img/bg.png'), #1D2529;
  // background-repeat: no-repeat;
  // background-size: calc((944px / 1920px) * 110%) auto, auto 100%;
  // background-position: 0 calc(100% + 80px), top left;
  background: url('@/assets/img/bg3.png');
  background-repeat: no-repeat;
  background-position:  center center;
  background-size:  cover;
  padding: 60px 0;
  min-height: 100vh;

  // @media(max-width:1399px) {
  //   background-size: calc((944px / 1920px) * 125%) auto, auto 100%;
  //   background-position: -30px calc(100% + 60px), top left;
  // }

  // @include d(lg) {
  //   background-size: calc((944px / 1920px) * 150%) auto, auto 100%;
  //   background-position: -100px calc(100% + 60px), top left;
  // }

  // @include d(md) {
  //   background: url('@/assets/img/moto.png'), url('@/assets/img/bg2.png'), #1D2529;
  //   background-repeat: no-repeat;
  //   background-size: calc((944px / 1920px) * 150%) auto, 100% auto;
  //   background-position: 50% calc(196px), top left;
  //   padding: 40px 0;
  // }

  // @include d(xs) {
  //   background: url('@/assets/img/moto.png'), url('@/assets/img/bg2.png'), #1D2529;
  //   background-repeat: no-repeat;
  //   background-size: calc((944px / 1920px) * 150%) auto, 150% auto;
  //   background-position: 50% calc(196px), top center;
  //   padding: 40px 0;
  // }

  

  @include d(sm) {
    background-size:  cover;
    background-position: center bottom;
    // padding: 0;
    padding-bottom: 50px;
  }

  @include d(xs) {
      background-size:  cover;
      background-position: center bottom;
  }

 

  .logoPetronas {
    position: absolute;
    top: 0px;
    right: 0;
    max-width: 83px;
    z-index: 9;

    @include d(md) {
      max-width: 77px;
    }

    @include d(sm) {
      max-width: 65px;
    }

    @include d(xs) {
      max-width: 60px;
      right: 10px;
    }
  }

  .logoEvento {
    position: absolute;
    top: 0px;
    left: 0;
    max-width: 488px;

    @include d(md) {
      max-width: 360px;
      left: calc(50% - 190px);
    }

    @include d(xs) {
      max-width: 200px;
      left: 15px;
      top: -10px;
    }
  }
}
</style>
