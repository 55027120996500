<template>
  <section id="containerRanking2">
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <div class="card">
                    <div class="card-header">
                        <i class="fas fa-trophy mr-2"></i>Ranking (Todos os dias)
                    </div>
                    <div class="card-body" v-if="loadedRanking">
                        <b-tabs content-class="mt-3" >
                            <b-tab title="Todos" active>
                                <b-table striped hover responsive :fields="fieldsTable" :items="rankingAll" thead-tr-class="text-center" tbody-tr-class="text-center">
                                    <template #cell(position)="data">
                                        <div class="text-center">
                                            {{data.index + 1}}º
                                        </div>
                                    </template>
                                </b-table>  
                            </b-tab>
                            <b-tab title="Geral">
                                <b-table striped hover responsive :fields="fieldsTable" :items="rankingUser" thead-tr-class="text-center" tbody-tr-class="text-center">
                                    <template #cell(position)="data">
                                        <div class="text-center">
                                            {{data.index + 1}}º
                                        </div>
                                    </template>
                                </b-table>  
                            </b-tab>
                            <b-tab title="Jornalistas">
                                <b-table striped hover responsive :fields="fieldsTable" :items="rankingJornalist" thead-tr-class="text-center" tbody-tr-class="text-center">
                                    <template #cell(position)="data">
                                        <div class="text-center">
                                            {{data.index + 1}}º
                                        </div>
                                    </template>
                                </b-table>  
                            </b-tab>
                        </b-tabs>
                    </div>
                    <div class="d-flex w-100 justify-content-center py-4" v-else>
                        <b-spinner/>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
import {mapState} from 'vuex'
export default {
name: 'RankingDashboard',
props:['loadedRanking'],
data() {
    return {
        fieldsTable : [
            {
                key: 'position',
                label: 'Posição'
            },
            {
                key: 'lead_id',
                label: 'ID'
            },
            {
                key: 'name',
                label: 'Nome'
            },
            {
                key: 'phone',
                label: 'Telefone'
            },
            {
                key: 'type',
                label: 'Tipo'
            },
            {
                key: 'duration_time',
                label: 'Tempo'
            },            
        ],
    }
},
computed: {
  ...mapState(['rankingAll','rankingUser','rankingJornalist'])
}
}
</script>

<style lang="scss" scoped>
#containerRanking2 {
    margin-top: 30px;
   

    .card-body {
        max-height: 400px;
        overflow-y: scroll;
    }
}
</style>