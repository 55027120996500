import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '@/views/Index.vue'
import Ranking from '@/views/Ranking.vue'
import StoreLead from '@/views/StoreLead.vue'
import LeadPlay from '@/views/LeadPlay.vue'
import Dashboard from '@/views/Dashboard.vue'
import pdfOndeComprar from '@/views/pdfOndeComprar.vue'
import PublicRanking from "@/views/PublicRanking.vue";

// import HomeView fro../views/StoreLeadView.vuevue'

Vue.use(VueRouter)

const routes = [
  { path: '/c2n0t2c3n', redirect: '/c2n0t2c3n/panel' },
  {
    path: '/c2n0t2c3n/panel',
    name: 'Index',
    component: Index
  },
  {
    path: '/c2n0t2c3n/ranking',
    name: 'Ranking',
    component: Ranking
  },
  {
    path: '/c2n0t2c3n/admin/lead',
    name: 'StoreLead',
    component: StoreLead
  },
  {
    path: '/c2n0t2c3n/admin/time',
    name: 'LeadPlayView',
    component: LeadPlay
  }, 
  {
    path: '/c2n0t2c3n/dashboard/qora5R7zBLV383b7X3Ti',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/c2n0t2c3n/onde-comprar-petronas-sprinta-pdf',
    name: 'pdfondecomprar',
    component: pdfOndeComprar
  },
  {
    path: '/',
    name: 'publicRanking',
    component: PublicRanking
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
