<template>
<section id="containerBudgetsDash">
  <div class="container-fluid">
    <div class="row align-items-stretch">
      <div class="col-12 col-sm-6 col-md-4 col-lg-3">
        <div class="boxBudget">
          <div v-if="loaded">
            <p class="mb-0 boxTitle">
              <i class="fas fa-users mr-2"></i>Total de cadastros
            </p>
            <p class="boxText mb-0">
              {{usersDay ? usersDay.length : '---'}}
            </p>
          </div>
          <div class="d-flex w-100 justify-content-center" v-else>
            <b-spinner/>
          </div>
        </div>
      </div>
      <div class="w-100 d-sm-none my-2"></div>
      <div class="col-12 col-sm-6 col-md-4 col-lg-3">
        <div class="boxBudget">
          <div v-if="loaded">
            <p class="mb-0 boxTitle">
              <i class="fas fa-gamepad mr-2"></i>Total de jogadas
            </p>
            <p class="boxText mb-0">
              {{ammountPlays ? ammountPlays : '---'}}
            </p>
          </div>
          <div class="d-flex w-100 justify-content-center" v-else>
            <b-spinner/>
          </div>
        </div>
      </div>
      <div class="w-100 d-md-none my-2"></div>
      <div class="col-12 col-sm-6 col-md-4 col-lg-3">
        <div class="boxBudget">
          <div v-if="loaded">
            <p class="mb-0 boxTitle">
              <i class="fas fa-dice mr-2"></i>Média de jogos por lead
            </p>
            <p class="boxText mb-0">
              {{usersDay && ammountPlays ? (ammountPlays / usersDay.length).toFixed(2) : '---'}}
            </p>
          </div>
          <div class="d-flex w-100 justify-content-center" v-else>
            <b-spinner/>
          </div>
        </div>
      </div>
      <div class="w-100 d-sm-none d-md-block d-lg-none my-2"></div>
      <div class="col-12 col-sm-6 col-md-4 col-lg-3">
        <div class="boxBudget">
          <div v-if="loaded">
            <p class="mb-0 boxTitle">
              <i class="fas fa-stopwatch mr-2"></i>Menor tempo
            </p>
            <p class="boxText mb-0">
              {{usersDay && rankingDay && rankingDay.length>0 ? rankingDay[0].duration : '--:--:---'}}
            </p>
          </div>
          <div class="d-flex w-100 justify-content-center" v-else>
            <b-spinner/>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
</template>

<script>

import {mapState} from 'vuex'
export default {
name: 'BudgetsDay',
props: ['loaded','indexInList','day'],
data() {
    return {
        usersDay: [],
        ammountPlays: 0,
        rankingDay: []
    }
},
methods: {
  loadDay() {
      this.usersDay = this.$store.state[`day${this.indexInList}`]

      for(var i=0;i<this.ammountDays;i++) {
        for(var j=0;j<this.$store.state[`day${i+1}`].length;j++) {

          this.ammountPlays+= this.$store.state[`day${i+1}`][j][`playsDay${this.indexInList}`].length
          if(this.$store.state[`day${i+1}`][j][`playsDay${this.indexInList}`][0]) {
              var playDay = this.$store.state[`day${i+1}`][j][`playsDay${this.indexInList}`][0]
              this.rankingDay = [...this.rankingDay,playDay]
          }
        }
      }

      this.rankingDay.sort((a,b)=>
        a.duration < b.duration ? -1 : 1
      )
  }
},
computed: {
  ...mapState(['analystics','ammountDays'])
},
created() {
  this.loadDay()
},
mounted(){
},

}
</script>

<style lang="scss" scoped>
#containerBudgetsDash {
    .boxBudget {
      border-radius: 8px;
      box-shadow: 0 0 3px rgba(0,0,0,.3);
      background: #fff;
      padding: 16px 22px;
      height: 100%;

      .boxTitle {
        font-size: 1.15rem;
        font-family: 'museo700';
      }

      .boxText {
        margin-top: 10px;
        font-size: 1.5rem;
        font-family: 'museo900';
      }
    }
}
</style>