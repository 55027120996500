<template>
  <div class="containerFormStoreLead">
    <p class="formTitle text-center">
      Preencha os campos e registre o jogador.
    </p>
    <b-form @submit.prevent="storeLead">
      <b-alert show :variant="alertMsg.variant" v-if="alertMsg.status">{{alertMsg.text}}</b-alert>
      <b-input-group class="cpfWithLoader" :class="{'invalidCPF':cpfError}">
        <b-form-input :state="!cpfError ? null : false" :disabled="gettingData" id="input-cpf" v-model="form.cpf" v-mask="'###.###.###-##'" type="text" placeholder="CPF" required></b-form-input>
      </b-input-group>
      <p class="invalidCpf" v-if="cpfError">Digite um CPF válido.</p>

      <b-form-group label-for="input-name">
        <b-form-input :disabled="gettingData" id="input-nema" v-model="form.name" type="text" placeholder="Nome/Veículo" required></b-form-input>
      </b-form-group>

       <b-form-group label-for="input-telefone">
        <b-form-input :disabled="gettingData" id="input-telefone" v-model="form.phone" v-mask="'(##) # ####-####'" type="text" placeholder="Telefone" required></b-form-input>
      </b-form-group>

      <b-form-group label-for="input-email">
        <b-form-input :disabled="gettingData" id="input-email" v-model="form.email" type="email" placeholder="E-mail" required></b-form-input>
      </b-form-group>

      <b-form-group label-for="input-password">
        <b-form-input :disabled="gettingData" id="input-password" v-model="form.password" type="text" placeholder="Senha" required></b-form-input>
      </b-form-group>

      <div class="d-flex justify-content-center containerCheckbox">
        <b-form-group label-for="input-checkbox">
        <b-form-checkbox id="input-checkbox" v-model="form.accept" name="checkbox-1" :value="true" :unchecked-value="false">
        Confirmo que li e concordo com o regulamento
        e a política de privacidade. 
        </b-form-checkbox>
      </b-form-group>
      </div>

      <!-- <b-button type="submit" variant="primary">Salvar</b-button> -->
      <div class="d-flex justify-content-center">
        <span class="d-inline-block" tabindex="0" v-b-tooltip.top :title="!form.accept ? 'Aceite o regulamento.' : ''">
          <button :disabled="bSave.disabled || !form.accept" type="submit" class="containerbSave d-flex align-items-center">
            {{bSave.text}}
          </button>
        </span>   
      </div>
      <!-- <div class="d-flex justify-content-center">
        <span class="d-inline-block" tabindex="0" v-b-tooltip.top :title="!form.accept ? 'Aceite o regulamento.' : ''">
          <img @click.prevent="storeLead()" src="@/assets/img/cta.svg" alt="" class="img-fluid ctaButton" :class="{'ctaDis':!form.accept ? true : false}">
        </span>        
      </div> -->


    </b-form>
  </div>
</template>

<script>
import { isCPF } from 'validation-br'
import {api} from '@/services.js'
export default {
name: 'FormStoreLead',
data() {
  return {
    gettingData: false,
    cpfError: false,
    leadLoaded: false,
    form: {
      cpf: null,
      phone: null,
      name: null,
      email: null,
      accept: false,
      password: null
    },
    alertMsg: {
      text: '',
      variant: '',
      status: false
    },
    bSave: {
      disabled: false,
      text: 'Salvar'
    },
    savedPassword: ''
  }
},
methods: {
  async storeLead() {
    this.cpfError = false
    this.bSave.disabled = true
    this.bSave.text = 'Salvando'
    this.alertMsg.status = false
    this.savedPassword = ''

    if(!this.form.accept) {
      this.bSave.text = 'Salvar'
      this.bSave.disabled = false
      return
    }

    if(!this.verifyIsCPF(this.form.cpf)) {
      this.cpfError = true
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
      this.bSave.text = 'Salvar'
      this.bSave.disabled = false
      return
    }

    var lead = {
      cpf: this.form.cpf.replaceAll(/[^0-9]+/g,''),
      phone: this.form.phone.replaceAll(/[^0-9]+/g,''),
      name: this.form.name,
      email: this.form.email,
      password: this.form.password
    }

    await api.storeLeads(lead).then(res=> {
      if(res.data.msg=="success") {
        this.savedPassword = this.form.password
        this.form = {
          cpf: null,
          phone: null,
          name: null,
          email: null,
          accept: false,
          password: null
        }
        this.alertMsg.text = 'Inscrição confirmada, guarde sua senha!'
        this.alertMsg.variant = 'success'
        this.alertMsg.status = true
      }else {
        if(res.data.code == 1) {
          this.alertMsg.text = "Erro ao cadastrar, tente novamente!"
          this.alertMsg.variant = "danger"
          this.alertMsg.status = true
        }
      }
    }).catch(err=> {
      if(err.response.status==422) {
        if(err.response.data.password) {
          this.alertMsg.text = "Senha já existente!"
          this.alertMsg.variant = "warning"
        }else if(err.response.data.email) {
          this.alertMsg.text = "Email incorreto!"
          this.alertMsg.variant = "warning"
        }else if(err.response.data.phone) {
          this.alertMsg.text = "Telefone incorreto!"
          this.alertMsg.variant = "warning"
        }else if(err.response.data.cpf) {
          this.alertMsg.text = "CPF já cadastrado!"
          this.alertMsg.variant = "warning"
        }else if(err.response.data.name) {
          this.alertMsg.text = "Nome incorreto!"
          this.alertMsg.variant = "warning"
        }else {
          this.alertMsg.text = "Erro ao cadastrar, tente novamente!"
          this.alertMsg.variant = "danger"
        }
      }else {
        this.alertMsg.text = "Erro ao cadastrar, tente novamente!"
        this.alertMsg.variant = "danger"
      }
      this.alertMsg.status = true
    })
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
    this.bSave.disabled = false
    this.bSave.text = 'Salvar'

    
    
  },
  verifyIsCPF(cpf){
    if(!isCPF(cpf)){
      return false
    }

    return true
  },
  formartDataInLeadFormat(form){
    return {
      cpf: form.cpf.replaceAll(/[^0-9]+/g,''),
      phone: form.phone.replaceAll(/[^0-9]+/g,''),
      name: form.name,
      email: form.email,
      accept: form.accept
    }
  },
}
}
</script>

<style lang="scss">
@import '@/assets/scss/responsive.scss';
@import '@/assets/scss/fonts.scss';
.containerFormStoreLead {
  // background: rgba(134, 136, 139, 0.555);
  background: rgba(236,238,241,.29);
  backdrop-filter: blur(10px);  
  border-radius: 20px;
  padding: 40px 46px;
  margin-top: 207px;
  border: 1px solid #fff;

  @include d(md) {
    margin-top: 30px;
  }

  // @include d(sm) {
  //   margin-top: 390px;
  // }

  // @include d(xs) {
  //   margin-top: 380px;
  //   padding: 30px 15px;
  // }

  // @media(max-width:520px) {
  //   margin-top: 350px;
  // }

  // @media(max-width:451px) {
  //   margin-top: 330px;
  // }

  // @media(max-width:400px) {
  //   margin-top: 300px;
  // }

  // @media(max-width:350px) {
  //   margin-top: 290px;
  // }

  

  .formTitle {
    font-family: 'museo500';
    font-style: normal;    
    font-size: 40px;
    line-height: 49px;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    margin-bottom: 50px;

    @include d(lg) {
      font-size: 30px;
      line-height: 39px;
    }

    @include d(md) {
      font-size: 24px;
      line-height: 30px;
    }

    @include d(xs) {
      margin-bottom: 30px;      
    }
  }

  input {
    background: rgba(0, 0, 0, 0.4) !important;
    // backdrop-filter: blur(20px);
    border: 0;
    border-radius: 10px;
    font-family: 'museo500';
    font-style: normal;
    font-size: 25px;
    line-height: 30px;
    color: #FFFDEB;

    &::placeholder {
      color: #FFFDEB;
    }

    &:focus {
      background: rgba(0, 0, 0, 0.4);
      color: #FFFDEB;
      box-shadow: none;
    }

    & + input {
      margin-top: 20px;
    }

    @include d(lg) {
      font-size: 22px;
      line-height: 25px;
    }

    @include d(xs) {
      font-size: 18px;
      line-height: 21px;
    }
  }

  .ctaButton {
    font-family: 'museo500';
    max-width: 264px;
    cursor: pointer;
    transition: .2s;

    &.ctaDis {
      opacity: .8;
      filter: brightness(.8);
    }

    &:not(.ctaDis):hover {
      filter: brightness(.95);
    }

    @include d(lg) {
      max-width: 250px;
    }

    @include d(md) {
      max-width: 230px;
    }

    @include d(xs) {
      max-width: 200px;
    }
  }

  .containerCheckbox {
    color: #fff;
  }

  .cpfWithLoader {

    &:not(.invalidCPF) {
      margin-bottom: 20px;
    }

    input {
      border-right: none;
    }

    .aside {
      background: #444849;
      backdrop-filter: blur(20px);
      border-radius: 0 10px 10px 0;
      padding: 0 10px;
    }

  }

  .containerbSave {
    background: #009D93;
    font-family: 'museo500';
    font-style: normal;
    font-weight: 400;
    font-size: 2rem;
    color: #FFFFFF;
    border-radius: 8px;
    border: none;
    padding: 5px 30px;
    // padding: 0px 30px 8px 30px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    margin-top: 20px;
    &:disabled {
      opacity: .7;
      filter: brightness(.9);
      cursor: not-allowed;
    }
  }
}
</style>
