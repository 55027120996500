import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    usersForDays: [],
    analystics: null,
    timeinfo: null,
    leads: null,
    rankingAll: null,
    rankingUser: null,
    rankingJornalist: null,
    participations: null,
    ammountDays: 0,
    initialDay: 22,
    analysticsFlap: null,
    analysticsFlapByDay: {

    }
  },
  getters: {
  },
  mutations: {
    SET_AMMOUNT_DAYS(state,payload) {
      state.ammountDays = payload
    },
    SET_ANALYSTICS(state,payload) {
      state.analystics = payload
    },
    SET_TIMEINFO(state,payload) {
      state.timeinfo = payload
    },
    SET_LEADS(state,payload) {
      state.leads = payload
    },
    SET_RANKING_ALL(state,payload) {
      state.rankingAll = payload
    },
    SET_RANKING_JORNALIST(state,payload) {
      state.rankingJornalist = payload
    },
    SET_RANKING_USER(state,payload) {
      state.rankingUser = payload
    },
    SET_PARTICIPATIONS(state,payload) {
      state.participations = payload
    },
    SET_USERS_FOR_DAYS(state,payload) {
      state.usersForDays = payload
    },
    ADD_ANALYSTICS(state,payload) {
      state.analystics[`${payload.day}`] = payload.data
    },
    CRAETE_DAY_STATE(state,payload) {
      state[`${payload}`] = []
      state.analysticsFlapByDay[`${payload}`] = []
    },
    ADD_DAY_STATE(state,payload) {
      state[`day${payload.day}`] = (payload.data)
    },
    SET_ANALYSTICS_FLAP(state,payload) {
      state.analysticsFlap = payload
    },
    ADD_DAY_STATE2(state,payload) {
      state.analysticsFlapByDay[`day${payload.day}`] = (payload.data)
    },
  },
  actions: {
    async createUsersWithPlaysArr(context) {
      var participations = context.state.participations
      if(!participations) {
        return
      }

      for(var l=0;l<context.state.ammountDays;l++) {
        context.commit('CRAETE_DAY_STATE',`day${l+1}`)

        var users = []
        for(var i=0;i<participations.length;i++) { // roda todas os usuários

          // Seta um date normal em cada participação (create_at)
          participations[i].dateNormal = new Date(participations[i].created_at).toLocaleDateString('pt-BR',{
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
          })
          
          // Roda todas as participações do atual usuário
          for(var j=0;j<participations[i].participation.length;j++) {

            // Seta um date normal em cada jogada (create_at)
            participations[i].participation[j].normalDate = new Date(participations[i].participation[j].created_at).toLocaleDateString('pt-BR',{
              month: '2-digit',
              day: '2-digit',
              year: 'numeric'
            })

            // Seta o dia que a jogada aconteceu
            participations[i].participation[j].dayOfPlay = parseInt(participations[i].participation[j].normalDate.split("/")[0])

            participations[i].participation[j].lead = {
              cpf:participations[i].cpf,
              created_at:participations[i].created_at,
              email:participations[i].email,
              id:participations[i].id,
              name:participations[i].name,
              phone:participations[i].phone,
              type:participations[i].type,

            }
            
          }
          
          participations[i][`playsDay${l+1}`] = participations[i].participation.filter(c=>parseInt(c.dayOfPlay)==parseInt(context.state.initialDay+l))

          if(parseInt(participations[i].dateNormal.split("/")[0] ) == parseInt(context.state.initialDay+l)) {

            users.push(participations[i])
          }
          
        }

        context.commit('ADD_DAY_STATE',{day:l+1,data:users})
      }

      context.dispatch('createFlapDays')
    },
    async createFlapDays(context) {
      var flapLeads = context.state.analysticsFlap.leads

      for(var m=0;m<context.state.ammountDays;m++) {
        var flapLeads2 = []
        for(var f=0;f<flapLeads.length;f++) {
          flapLeads[f].dateNormal = new Date(flapLeads[f].created_at).toLocaleDateString('pt-BR',{
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
          })

          flapLeads[f].dayOfPlay = parseInt(flapLeads[f].dateNormal.split("/")[0])

          if(flapLeads[f].dayOfPlay==parseInt(context.state.initialDay+m)) {
            flapLeads2.push(flapLeads[f])           
          }
          
        }

        
        context.commit('ADD_DAY_STATE2',{day:m+1,data:flapLeads2})
      }
    }
  },
  modules: {
  }
})
